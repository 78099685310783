var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "left", "margin-top": "10px" },
              attrs: { span: "12" },
            },
            [
              _c("label", {
                staticStyle: { "font-size": "14px", "margin-left": "1px" },
              }),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: { "text-align": "right", "margin-top": "5px" },
              attrs: { span: "12" },
            },
            [
              _vm.saveBtnShow
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.save },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }